<template>
  <div>
    <el-result :icon="tipData.Tip == 'SUCCESS' ? 'success' : 'error'" :title="tipData.title"
      :subTitle="tipData.Tip == 'SUCCESS' ? '恭喜你，支付成功' : '哎呀，充值好像失败了哟！'">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="setClose">我知道了</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import {msgFun} from '@/plugins/msg'
import myBus from '@/plugins/myBus'
export default {
  data() {
    return {
      tipData: {
        Tip: 'SUCCESS',
        title: '支付成功'
      }
    }
  },
  mounted(){
    myBus.$on("msgs", function (val) {
      this.tipData.Tip = val
      this.tipData.title =  msgFun(val)
    }.bind(this));
   
  },
  methods: {
    setClose(){
      this.$emit('setClose',false)
    }
  }
}

</script>
  