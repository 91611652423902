export function msgFun(msg){
    switch (msg) {
        case 'SUCCESS':
            return '支付成功!'
            break;
        case 'NOTPAY':
            return '未支付 '
            break;
        case 'CLOSED':
            return '已关闭 '
            break;
        case 'REVOKED':
            return '已撤销'
            break;
        case 'USERPAYING':
            return '用户支付中'
            break;
        default:
            return '支付失败'
            break;
    }
}